import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  RouterStateSnapshot,
} from '@angular/router';
import { NavigationService } from '@services';
import { UserStore } from '@stores';
import { AuthenticationService } from '../authentication.service';

export const authorizationGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  inject(NavigationService).navigation.set(state.url.slice(1) as any);
  if (inject(UserStore).isAuthenticated()) {
    return true;
  }
  inject(AuthenticationService).logoutUser();
  return false;
};
