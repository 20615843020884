import { Route } from '@angular/router';
import { authorizationGuard } from './core/authentication/guards/authorization.guard';

export const appRoutes: Route[] = [
  {
    path: '',
    title: 'Login',
    loadComponent: () =>
      import('./pages/login-page/login-page').then((c) => c.LoginPage),
  },
  {
    path: 'login',
    title: 'Login',
    loadComponent: () =>
      import('./pages/login-page/login-page').then((c) => c.LoginPage),
  },
  {
    path: 'scenes',
    title: 'Start scenes',
    loadComponent: () =>
      import('./pages/scenes-page/scenes-page').then((c) => c.ScenesPage),
    canActivate: [authorizationGuard],
  },
  {
    path: 'dashboard',
    title: 'Dashboard',
    loadComponent: () =>
      import('./pages/dashboard-page/dashboard-page').then(
        (c) => c.DashboardPage,
      ),
    canActivate: [authorizationGuard],
  },
  {
    path: 'runner',
    title: 'Runner',
    loadComponent: () =>
      import('./pages/runner-page/runner-page').then((c) => c.RunnerPage),
    canActivate: [authorizationGuard],
  },
  {
    path: 'evaluation',
    title: 'Evaluation',
    loadComponent: () =>
      import('./pages/evaluation-page/evaluation-page').then(
        (c) => c.EvaluationPage,
      ),
    canActivate: [authorizationGuard],
  },
  {
    path: 'uploads',
    title: 'Uploads',
    loadComponent: () =>
      import('./pages/uploads-page/uploads-page').then((c) => c.UploadsPage),
    canActivate: [authorizationGuard],
  },
];
