import { NgClass } from '@angular/common';
import { Component, effect, inject } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Debug } from '@components';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from '@services';
import { AppStore } from '@stores';
import { PrimeNG } from 'primeng/config';
import { preset } from 'styles/primeng/preset';
import { AuthenticationService } from './core/authentication/authentication.service';
import { InitializationService } from './core/authentication/initialization.service';
import { AppStorageService } from './core/services/app-storage.service';
import { ReportsStore } from './core/stores/reports.store';

@Component({
  standalone: true,
  imports: [Debug, NgClass, RouterModule],
  selector: 'wl-root',
  templateUrl: './app.html',
})
export class App {
  title = 'WebLauncher';
  protected appStore = inject(AppStore);
  protected appService = inject(AppService);
  private authenticationService = inject(AuthenticationService);
  private initializationService = inject(InitializationService);
  private primeNg = inject(PrimeNG);
  private translate = inject(TranslateService);
  private appStorageService = inject(AppStorageService);
  private reportsStore = inject(ReportsStore);
  private router = inject(Router);

  constructor() {
    effect(
      () => {
        this.primeNg.theme.set({
          preset: preset(this.appStore.branding()),
          options: {
            darkModeSelector: 'light',
          },
        });
      },
      { allowSignalWrites: true },
    );

    this.authenticationService.storageLogin();
    if (!this.appStore.isOnline()) {
      /*       this.initializationService.offlineInitialization(); */
    }
    this.translate.setDefaultLang('en');
    this.translate.use('en');

    this.appStorageService.getItem('reports').then((reports) => {
      if (reports) {
        this.reportsStore.setReports(reports);
      }
    });
  }
}
