import { definePreset } from '@primeng/themes';
import Aura from '@primeng/themes/aura';
import { button } from './button';
import { checkbox } from './checkbox';
import { inputtext } from './input-text';
import { modifications } from './modifications';

export const preset = (branding: 'manta' | 'virtamed') =>
  definePreset(Aura, {
    ...modifications(branding),
    components: {
      button: button(branding),
      checkbox: checkbox(branding),
      inputtext: inputtext(branding),
    },
  });
