import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandlerFn,
  HttpRequest,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { UserStore } from '@stores';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  of,
  switchMap,
  take,
  throwError,
} from 'rxjs';
import { environment } from '../../../environments/environment';
import {
  RefreshTokenData,
  TokenData,
} from '../authentication/models/api/token.interfaces';
import { TokenService } from '../authentication/token.service';

let isRefreshing = false;
const refreshTokenSubject = new BehaviorSubject<any>(null);

export function authorizationInterceptor(
  req: HttpRequest<unknown>,
  next: HttpHandlerFn,
): Observable<HttpEvent<unknown>> {
  const tokenService = inject(TokenService);
  const userStore = inject(UserStore);

  let authReq: HttpRequest<unknown> | null = null;
  if (req.url.startsWith('/api/')) {
    const userToken: TokenData | null = tokenService.token;
    authReq = req.clone({
      ...req,
      url: environment.backendUrl + req.url,
      setHeaders: userToken
        ? {
            Authorization: userToken.tokenType + ' ' + userToken.accessToken,
          }
        : {},
      headers: req.headers.set('Accept-Language', userStore.language()),
    });
  } else if (req.url.startsWith('/identity')) {
    authReq = req.clone({
      url: environment.identityUrl + req.url.replace('/identity', ''),
      headers: req.headers
        .set('Accept', 'application/json')
        .set('Content-Type', 'application/x-www-form-urlencoded'),
    });
  }
  return next(authReq || req).pipe(
    catchError((err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (authReq) {
          if (!isRefreshing) {
            isRefreshing = true;
            refreshTokenSubject.next(null);
            const refreshToken = tokenService.token.refreshToken;
            return tokenService.acquireAccessToken(refreshToken).pipe(
              switchMap((identityToken: RefreshTokenData) => {
                const mappedTokenModel: TokenData = {
                  accessToken: identityToken.access_token,
                  refreshToken: identityToken.refresh_token,
                  expiresIn: identityToken.expires_in,
                  tokenType: identityToken.token_type,
                };
                isRefreshing = false;
                tokenService.token = mappedTokenModel;
                refreshTokenSubject.next(mappedTokenModel);
                return next(
                  addTokenHeader(authReq, mappedTokenModel.accessToken),
                );
              }),
              catchError(() => {
                isRefreshing = false;
                /* this.translateService
                      .get('C.F.HttpInterceptor.Unauthorized')
                      .subscribe((tr) => {
                        this.router.navigate([AppConstants.DEFAULT_LOGOUT_PAGE]);
                        this.toastrService.error(tr);
                      }); */
                return of(null);
              }),
            );
          }

          return refreshTokenSubject.pipe(
            filter((token) => token !== null),
            take(1),
            switchMap((token) =>
              next(addTokenHeader(authReq, token.accessToken)),
            ),
          );
        }
        return throwError(() => err);
      }
      return throwError(() => err);
    }),
  ) as Observable<HttpEvent<unknown>>;
}

function addTokenHeader(
  request: HttpRequest<any>,
  token: string,
): HttpRequest<any> {
  return request.clone({
    headers: request.headers.set('authorization', 'Bearer ' + token),
  });
}
