import { colors } from '../../app/themes/theme.utils';
import { Branding } from '@models';

export const modifications = (branding: Branding) => ({
  borderRadius: {
    none: '0',
    xs: '4px',
    sm: '6px',
    md: '8px',
    lg: '10px',
    xl: '14px',
  },
  semantic: {
    colorScheme: {
      primary: {
        color: colors(branding)['wl-primary'],
        contrastColor: colors(branding)['wl-white'],
        hoverColor: colors(branding)['wl-accent'],
        activeColor: colors(branding)['wl-primary'],
      },
      highlight: {
        background: '{primary.50}',
        focusBackground: '{primary.100}',
        color: '{primary.700}',
        focusColor: '{primary.800}',
      },
    },
  },
});
